<template>
    <div></div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    beforeCreate() {},
    beforeDestroy() {},
    mounted() {
        this.getBizInfo();
    },
    methods: {
        getBizInfo() {
            let code = this.$route.query.code;
            let source = this.$route.query.source; //ali wx
            let type = this.$route.query.type; //ali wx
            let marketChannel = this.$route.query.marketChannel || '';
            let discountCode = this.$route.query.discountCode || '';
            let resourceId = this.$route.query.resourceId || '';
            let contactId = this.$route.query.contactId || '';
            let isScan = this.$route.query.isScan || true;
            console.log(code, source, type);
            //目前仅针对支付宝小程序开放
            //线下门店二维码
            if (type == 'pharmacyStore' && source == 'ali') {
                let appId = process.env.NODE_ENV === 'production' ? 2021003174630433 : 2021003173692931;
                let pageurl = `pages/follow-prescribe/drug-store-index?userCode=${code}&marketChannel=${marketChannel}&discountCode=${discountCode}&resourceId=${resourceId}&contactId=${contactId}&isScan=${isScan}`;
                let aliurl = `alipays://platformapi/startapp?appId=${appId}&page=` + encodeURIComponent(pageurl);
                let newUrl = `https://ds.alipay.com/?scheme=` + encodeURIComponent(aliurl);
                console.log('aliurl:', aliurl, 'newUrl:', newUrl);
                window.location.href = newUrl;
            }
            //正常支付宝扫码
            else if (source == 'ali') {
                this._navigator(code);
            }
        },
        _navigator(code) {
            this.$axios.get(`/config/code/mapping/get/param?code=${code}`).then(res => {
                let data = res.data || '';
                let url = this.b64Decode(data);
                let newUrl = `https://ds.alipay.com/?scheme=` + encodeURIComponent(url);
                console.log('url:', url, 'newUrl:', newUrl);
                window.location.href = newUrl;
            });
        },
        b64Encode(str) {
            return btoa(str);
        },
        b64Decode(str) {
            return atob(str);
        }
    }
};
</script>

<style lang="scss" scoped></style>
